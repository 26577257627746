'use client';

import { useActionState, useEffect } from 'react';
import { Dialog, Button, Text, TextField } from '@radix-ui/themes';
import { useForm, getFormProps, getInputProps } from '@conform-to/react';
import { parseWithZod, getZodConstraint } from '@conform-to/zod';
import { createNewList } from '~/app/(app)/_modules/sidebar/actions';
import { useRouter } from 'next/navigation';
import { createNewListSchema } from '~/app/(app)/_modules/sidebar/schemas';
const NewListDialog = ({
  open,
  onOpenChange
}: Readonly<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
}>) => {
  const {
    refresh
  } = useRouter();
  const [lastResult, action, isPending] = useActionState(createNewList, undefined);
  const [form, fields] = useForm({
    lastResult,
    constraint: getZodConstraint(createNewListSchema),
    onValidate: ({
      formData
    }) => {
      return parseWithZod(formData, {
        schema: createNewListSchema
      });
    },
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onSubmit'
  });
  const {
    key: nameKey,
    ...nameProps
  } = getInputProps(fields.name, {
    type: 'text'
  });
  useEffect(() => {
    if (!isPending && !lastResult) {
      onOpenChange(false);
      refresh();
    }
  }, [refresh, isPending, lastResult, onOpenChange]);
  return <Dialog.Root open={open} onOpenChange={onOpenChange} data-sentry-element="unknown" data-sentry-component="NewListDialog" data-sentry-source-file="new-list-dialog.tsx">
			<Dialog.Content asChild={true} data-sentry-element="unknown" data-sentry-source-file="new-list-dialog.tsx">
				<form {...getFormProps(form)} className="flex max-w-md flex-col gap" action={action}>
					<div>
						<Dialog.Title data-sentry-element="unknown" data-sentry-source-file="new-list-dialog.tsx">
							{'Create List'}
						</Dialog.Title>
						<Dialog.Description data-sentry-element="unknown" data-sentry-source-file="new-list-dialog.tsx">
							{'Create a new list to curate your network.'}
						</Dialog.Description>
					</div>
					<div className="flex flex-col gap">
						<label>
							<Text as="div" size="2" mb="1" weight="bold" data-sentry-element="Text" data-sentry-source-file="new-list-dialog.tsx">
								{'Name'}
							</Text>
							<TextField.Root key={nameKey} {...nameProps} size="3" placeholder="eg. Pre-seed investors" autoComplete="off" data-1p-ignore data-sentry-element="unknown" data-sentry-source-file="new-list-dialog.tsx" />
							{fields.name.errors ? <Text as="div" size="1" className="mt-sm" color="crimson">
									{fields.name.errors.map(error => {
                return error;
              })}
								</Text> : null}
						</label>
					</div>
					<div className="mt flex justify-end gap">
						<Dialog.Close data-sentry-element="unknown" data-sentry-source-file="new-list-dialog.tsx">
							<Button variant="soft" color="gray" type="button" data-sentry-element="Button" data-sentry-source-file="new-list-dialog.tsx">
								{'Cancel'}
							</Button>
						</Dialog.Close>
						<Button loading={isPending} type="submit" data-sentry-element="Button" data-sentry-source-file="new-list-dialog.tsx">
							{'Create'}
						</Button>
					</div>
				</form>
			</Dialog.Content>
		</Dialog.Root>;
};
export default NewListDialog;