'use client';

import { useDndContext } from '~/modules/draggable-people/draggable-context';
import type { ReactNode } from 'react';
const CurrentListsDropzone = ({
  children,
  hasCurrentLists
}: Readonly<{
  children: ReactNode;
  hasCurrentLists: boolean;
}>) => {
  const {
    draggingItems
  } = useDndContext();
  if (draggingItems.length === 0 || !hasCurrentLists) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
				{children}
			</>
    );
  }
  return <div className="rounded border-2 border-dashed border-dunbar-purple py-sm" data-sentry-component="CurrentListsDropzone" data-sentry-source-file="current-lists-dropzone.tsx">
			<div className="px py-xs text-center text-sm font-bold text-neutral-700">
				{'Add to a current list'}
			</div>
			{children}
		</div>;
};
export default CurrentListsDropzone;