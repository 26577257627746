import type { ReactNode, ComponentProps } from 'react';
import { Badge } from '@radix-ui/themes';
import { cn } from '~/utils/cn';
import Link from 'next/link';
const ListTemplate = ({
  name,
  icon,
  ref,
  badge,
  href,
  className,
  isActiveList = false
}: Readonly<{
  name: string;
  icon: ReactNode;
  href?: ComponentProps<typeof Link>['href'];
  ref?: (element: HTMLElement | null) => void;
  badge?: ReactNode | string;
  className?: string;
  isActiveList?: boolean;
}>) => {
  const children = <>
			<div className="flex w-full min-w-0 items-center gap-sm overflow-hidden text-sm">
				<div className="block">
					{icon}
				</div>
				<div title={name} className="mr-sm truncate whitespace-nowrap">
					{name}
				</div>
			</div>
			{badge ? <Badge color={isActiveList ? 'purple' : 'gray'}>
					{badge}
				</Badge> : null}
		</>;
  if (href !== undefined) {
    return <LinkContainer ref={ref} href={href} name={name} isActiveList={isActiveList} className={className}>
				{children}
			</LinkContainer>;
  }
  return <BasicContainer name={name} ref={ref} className={className} data-sentry-element="BasicContainer" data-sentry-component="ListTemplate" data-sentry-source-file="list-template.tsx">
			{children}
		</BasicContainer>;
};
const BasicContainer = ({
  name,
  children,
  ref,
  className
}: Readonly<{
  name: string;
  children: ReactNode;
  ref?: (element: HTMLElement | null) => void;
  className?: string;
}>) => {
  return <div ref={ref} aria-label={`${name} list`} className={cn('flex w-full cursor-not-allowed items-center justify-between px py-xs font-medium hover:bg-neutral-200', className)} data-sentry-component="BasicContainer" data-sentry-source-file="list-template.tsx">
			{children}
		</div>;
};
const LinkContainer = ({
  href,
  name,
  children,
  isActiveList = false,
  ref,
  className
}: Readonly<{
  href: ComponentProps<typeof Link>['href'];
  name: string;
  children: ReactNode;
  isActiveList?: boolean;
  ref?: (element: HTMLElement | null) => void;
  className?: string;
}>) => {
  return <Link ref={ref} href={href} className={cn('flex w-full cursor-pointer items-center justify-between px py-xs font-medium', isActiveList ? 'bg-dunbar-purple text-white hover:bg-dunbar-purple/80' : 'hover:bg-neutral-200', className)} aria-label={`${name} list`} data-sentry-element="Link" data-sentry-component="LinkContainer" data-sentry-source-file="list-template.tsx">
			{children}
		</Link>;
};
export default ListTemplate;