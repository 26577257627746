import type { FeedItem } from '@knocklabs/client';
import { z } from 'zod';
import RequestRelationshipContextNotification from './_modules/request-relationship-context';
import SuperConnectorRequestNotification from './_modules/super-connector-request';
import LinkedInSyncCompleteNotification from './_modules/linkedin-sync-complete';
import CSVImportCompleteNotification from './_modules/csv-import-complete';
import { NotificationItem } from './_modules/notification-item';
const requestRelationshipContextData = z.object({
  key: z.literal('request-relationship-context'),
  data: z.object({}).optional()
});
const superConnectorRequestData = z.object({
  key: z.literal('super-connector-request'),
  data: z.object({
    connectorName: z.string(),
    connectorAvatar: z.string()
  })
});
const linkedInSyncCompleteData = z.object({
  key: z.literal('linkedin-sync-complete'),
  data: z.object({
    linkedInListId: z.string()
  })
});
const csvImportComplete = z.object({
  key: z.literal('csv-import-complete'),
  data: z.object({
    fileName: z.string(),
    listId: z.number()
  })
});
const notificationDataSchema = z.discriminatedUnion('key', [requestRelationshipContextData, superConnectorRequestData, linkedInSyncCompleteData, csvImportComplete]);
const Notification = ({
  item
}: Readonly<{
  item: FeedItem;
}>) => {
  const validatedData = notificationDataSchema.safeParse({
    key: item.source.key,
    data: item.data
  });
  if (!validatedData.success) {
    console.error('Invalid notification data:', validatedData.error);
    return null;
  }
  const {
    key,
    data
  } = validatedData.data;
  const block = item.blocks[0];
  let message = '';
  switch (block.type) {
    case 'markdown':
      message = block.content;
      break;
    case 'text':
      message = block.content;
      break;
    case 'button_set':
      message = '';
      break;
  }
  return <NotificationItem.Root isRead={item.seen_at !== null} data-sentry-element="unknown" data-sentry-component="Notification" data-sentry-source-file="notification.tsx">
			<NotificationItem.Header actor={item.actors[0]} timeStamp={item.inserted_at} data-sentry-element="unknown" data-sentry-source-file="notification.tsx" />
			<NotificationItem.Content message={message} data-sentry-element="unknown" data-sentry-source-file="notification.tsx">
				<div className="mt-4">
					{(() => {
          switch (key) {
            case 'request-relationship-context':
              return <RequestRelationshipContextNotification />;
            case 'super-connector-request':
              return <SuperConnectorRequestNotification connectorName={data.connectorName} connectorAvatar={data.connectorAvatar} />;
            case 'linkedin-sync-complete':
              return <LinkedInSyncCompleteNotification listId={data.linkedInListId} />;
            case 'csv-import-complete':
              return <CSVImportCompleteNotification listId={data.listId} />;
            default:
              return null;
          }
        })()}
				</div>
			</NotificationItem.Content>
		</NotificationItem.Root>;
};
export default Notification;