'use client';

import { createRequiredContext } from '~/utils/create-required-context';
import type { ReactNode } from 'react';
const [Provider, useContext] = createRequiredContext<{
  customerId: number;
}>();
export const CustomerProvider = ({
  children,
  customerId
}: Readonly<{
  children: ReactNode;
  customerId: number;
}>) => {
  return <Provider value={{
    customerId
  }} data-sentry-element="Provider" data-sentry-component="CustomerProvider" data-sentry-source-file="customer-provider.tsx">
			{children}
		</Provider>;
};
export const useCustomer = () => {
  return useContext();
};