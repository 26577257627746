'use client';

import { createRequiredContext } from '~/utils/create-required-context';
import { type ReactNode, useEffect, useState } from 'react';
import { client } from '~/trpc/client';
import type { RouterOutput } from '~/trpc/types';
const [Provider, useContext] = createRequiredContext<{
  syncData?: RouterOutput['sync']['getCurrentSyncState'];
  linkedIn: {
    syncing: true;
    stage: 'preparing' | 'ingestion' | 'enrichment' | 'summarization' | 'vectorization' | 'profiling' | 'measuring_connection_strength' | 'finalizing';
  } | {
    syncing: false;
    stage: null;
  };
  reload: () => void;
}>();
export const SyncProvider = ({
  children
}: Readonly<{
  children: ReactNode;
}>) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const {
    data: syncData,
    refetch
  } = client.sync.getCurrentSyncState.useQuery(undefined, {
    refetchIntervalInBackground: true,
    refetchInterval: 3000,
    enabled: isEnabled
  });
  useEffect(() => {
    setIsEnabled(Boolean(syncData?.linkedIn && !syncData.linkedIn.completedAt));
  }, [syncData?.linkedIn]);
  return <Provider value={{
    syncData,
    linkedIn: syncData?.linkedIn && !syncData.linkedIn.completedAt ? {
      syncing: true,
      stage: syncData.linkedIn.stage
    } : {
      syncing: false,
      stage: null
    },
    reload: () => {
      void refetch();
    }
  }} data-sentry-element="Provider" data-sentry-component="SyncProvider" data-sentry-source-file="sync-provider.tsx">
			{children}
		</Provider>;
};
export const useSync = () => {
  return useContext();
};