'use client';

import { Gift, LinkedinLogo, XLogo, FacebookLogo, Envelope } from '@phosphor-icons/react/dist/ssr';
import { Dialog, Flex, TextField, Button, IconButton, Spinner } from '@radix-ui/themes';
import { client } from '~/trpc/client';
import Link from 'next/link';
import { publicConfig } from '~/config';
import CopyCodeButton from '../../gift/_modules/copy-code-button';
const GiftLink = () => {
  const {
    data,
    isLoading
  } = client.giftLink.getOrCreateGiftLink.useQuery();
  const shareUrl = `${publicConfig.app.host}/share/gift/${data?.code}`;
  const shareText = 'Join me on Dunbar! Use my code to get access:';
  const shareTo = {
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
    x: `https://x.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
    email: `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(shareUrl)}`
  };
  return <Dialog.Root data-sentry-element="unknown" data-sentry-component="GiftLink" data-sentry-source-file="gift-link.tsx">
			<Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
				<button className="flex w-full items-center gap-2 px py-sm font-medium hover:bg-neutral-200">
					<Gift size={24} data-sentry-element="Gift" data-sentry-source-file="gift-link.tsx" />
					<span>
						{'Gift Dunbar'}
					</span>
				</button>
			</Dialog.Trigger>
			<Dialog.Content maxWidth="450px" data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
				<Dialog.Title className="mb-1 text-center" data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
					{'Share Dunbar with a friend'}
				</Dialog.Title>
				<Dialog.Description size="2" mb="4" className="text-center" data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
					{'Each time your gift link is used, you\'ll get 10 extra search credits added to your monthly quota and 2 additional super connectors.'}
				</Dialog.Description>
				<div>
					<div className="flex w-full items-center gap-sm">
						<TextField.Root value={`${publicConfig.app.host}/share/gift/${data?.code}`} className="w-full" readOnly size="2" variant="classic" data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
							<TextField.Slot data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
								{isLoading ? <Spinner /> : <Gift height="16" width="16" />}

							</TextField.Slot>
						</TextField.Root>

					</div>
				</div>
				<div className="mt-4 flex items-center gap-3">
					<span>
						{'Share via'}
					</span>
					<Link href={shareTo.linkedin} target="_blank" data-sentry-element="Link" data-sentry-source-file="gift-link.tsx">
						<IconButton variant="soft" radius="full" data-sentry-element="IconButton" data-sentry-source-file="gift-link.tsx">
							<LinkedinLogo data-sentry-element="LinkedinLogo" data-sentry-source-file="gift-link.tsx" />
						</IconButton>
					</Link>
					<Link href={shareTo.x} target="_blank" data-sentry-element="Link" data-sentry-source-file="gift-link.tsx">
						<IconButton variant="soft" radius="full" data-sentry-element="IconButton" data-sentry-source-file="gift-link.tsx">
							<XLogo data-sentry-element="XLogo" data-sentry-source-file="gift-link.tsx" />
						</IconButton>
					</Link>
					<Link href={shareTo.facebook} target="_blank" data-sentry-element="Link" data-sentry-source-file="gift-link.tsx">
						<IconButton variant="soft" radius="full" data-sentry-element="IconButton" data-sentry-source-file="gift-link.tsx">
							<FacebookLogo data-sentry-element="FacebookLogo" data-sentry-source-file="gift-link.tsx" />
						</IconButton>
					</Link>
					<Link href={shareTo.email} target="_blank" data-sentry-element="Link" data-sentry-source-file="gift-link.tsx">
						<IconButton variant="soft" radius="full" data-sentry-element="IconButton" data-sentry-source-file="gift-link.tsx">
							<Envelope data-sentry-element="Envelope" data-sentry-source-file="gift-link.tsx" />
						</IconButton>
					</Link>
				</div>
				<Flex gap="3" mt="4" justify="end" data-sentry-element="Flex" data-sentry-source-file="gift-link.tsx">
					<Dialog.Close data-sentry-element="unknown" data-sentry-source-file="gift-link.tsx">
						<Button variant="soft" color="gray" data-sentry-element="Button" data-sentry-source-file="gift-link.tsx">
							{'Cancel'}
						</Button>
					</Dialog.Close>
					<CopyCodeButton code={data?.code ?? ''} data-sentry-element="CopyCodeButton" data-sentry-source-file="gift-link.tsx" />
				</Flex>
			</Dialog.Content>
		</Dialog.Root>;
};
export default GiftLink;