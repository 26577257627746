import { Button } from '@radix-ui/themes';
import Link from 'next/link';
const RequestRelationshipContextNotification = () => {
  return <div className="flex items-center gap-2" data-sentry-component="RequestRelationshipContextNotification" data-sentry-source-file="request-relationship-context.tsx">
			<Link href="/people/contacts" data-sentry-element="Link" data-sentry-source-file="request-relationship-context.tsx">
				<Button variant="classic" data-sentry-element="Button" data-sentry-source-file="request-relationship-context.tsx">
					{'Let\'s do it'}
				</Button>
			</Link>
		</div>;
};
export default RequestRelationshipContextNotification;