import { Button } from '@radix-ui/themes';
import Link from 'next/link';
const LinkedInSyncCompleteNotification = ({
  listId
}: Readonly<{
  listId: string;
}>) => {
  return <div className="flex items-center gap-2" data-sentry-component="LinkedInSyncCompleteNotification" data-sentry-source-file="linkedin-sync-complete.tsx">
			<Link href={`/lists/${listId}`} data-sentry-element="Link" data-sentry-source-file="linkedin-sync-complete.tsx">
				<Button variant="classic" data-sentry-element="Button" data-sentry-source-file="linkedin-sync-complete.tsx">
					{'View connections'}
				</Button>
			</Link>
		</div>;
};
export default LinkedInSyncCompleteNotification;