'use client';

import { useDndContext } from '~/modules/draggable-people/draggable-context';
import { DroppableArea } from '~/modules/draggable-people/droppable-area';
import { cn } from '~/utils/cn';
const NewListDropzone = () => {
  const {
    draggingItems
  } = useDndContext();
  if (draggingItems.length === 0) {
    return null;
  }
  return <DroppableArea id={0} label="New List" data-sentry-element="DroppableArea" data-sentry-component="NewListDropzone" data-sentry-source-file="new-list-dropzone.tsx">
			{({
      ref,
      isOver
    }) => {
      return <div ref={ref} className={cn('mb rounded border-2 border-dashed border-dunbar-purple py-sm text-center text-sm font-bold text-neutral-700', isOver && 'bg-dunbar-purple/20')}>
						{'New List'}
					</div>;
    }}
		</DroppableArea>;
};
export default NewListDropzone;