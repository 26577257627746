'use client';

// @Dak to refactor ids here to use network type and network id.
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState, useCallback } from 'react';
import type { DragStartEvent } from '@dnd-kit/core';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { client } from '~/trpc/client';
import { toast } from 'sonner';
import pluralize from 'pluralize';
import NewListWithDropDialog from '~/modules/draggable-people/new-list-with-drop-dialog';
import { useRouter } from 'next/navigation';
type DraggableItem = {
  id: string; // networkType-networkId format
  networkType: 'linkedin' | 'csv' | 'x';
  networkId: string;
  label: string;
};

/** What our context exposes to consumers */
type DndContextValue = {
  /** All currently selected items across the app */
  selectedItems: DraggableItem[];
  /** Toggle selection of a particular item */
  toggleSelection: (item: DraggableItem) => void;
  /** Check if an item is currently selected */
  isItemSelected: (id: string) => boolean;
  /** Called by a Draggable component on mount */
  registerItem: (item: DraggableItem) => void;
  /** Called by a Draggable component on unmount */
  unregisterItem: (item: DraggableItem) => void;
  /** Items currently being dragged */
  draggingItems: DraggableItem[];
};

/** Internal React context */
const DndContextState = createContext<DndContextValue | undefined>(undefined);

/**
 * The global provider for drag-and-drop state, including:
 * - Multi-selection handling
 * - Reference counting to auto-deselect unmounted items
 * - DndContext from Dnd Kit (with drag events)
 * - Optional DragOverlay for custom previews
 */
export function DndContextProvider({
  children
}: Readonly<PropsWithChildren>) {
  // Track all selected items
  const [selectedItems, setSelectedItems] = useState<DraggableItem[]>([]);
  const [isOver, setIsOver] = useState(false);
  const [dialogItems, setDialogItems] = useState<{
    networkId: string;
    networkType: 'linkedin' | 'csv' | 'x';
  }[]>([]);
  const {
    refresh
  } = useRouter();

  // Track how many times each item is rendered (for auto-deselect when unmounted)
  const [, setItemRefCount] = useState<Record<string, number>>({});

  // Track which items are currently being dragged
  const [draggingItems, setDraggingItems] = useState<DraggableItem[]>([]);

  // Toggle selection: if item is selected, deselect it; otherwise add it
  const toggleSelection = useCallback((item: DraggableItem) => {
    setSelectedItems(prev => {
      const selectedSet = new Set(prev.map(i => {
        return i.id;
      }));
      return selectedSet.has(item.id) ? prev.filter(i => {
        return i.id !== item.id;
      }) : [...prev, item];
    });
  }, []);

  // Check if an item is currently selected
  const isItemSelected = useCallback((id: string) => {
    return selectedItems.some(item => {
      return item.id === id;
    });
  }, [selectedItems]);

  // Register an item when a Draggable mounts (increment ref count)
  const registerItem = useCallback((item: DraggableItem) => {
    setItemRefCount(prev => {
      const count = prev[item.id] || 0;
      return {
        ...prev,
        [item.id]: count + 1
      };
    });
  }, []);

  // Unregister an item when a Draggable unmounts (decrement ref count)
  // If count hits 0, remove it from selectedItems
  const unregisterItem = useCallback((item: DraggableItem) => {
    setItemRefCount(prev => {
      const count = prev[item.id] || 0;
      const newCount = Math.max(0, count - 1);
      const updated = {
        ...prev,
        [item.id]: newCount
      };
      if (newCount === 0) {
        // Remove from selection if no references remain
        setSelectedItems(prevSel => {
          return prevSel.filter(i => {
            return i.id !== item.id;
          });
        });
      }
      return updated;
    });
  }, []);

  // Called by Dnd Kit when the drag starts
  const onDragStart = useCallback((event: DragStartEvent) => {
    const activeItem = event.active.data.current?.item as DraggableItem | undefined;
    if (!activeItem) {
      return;
    }

    // If the dragged item isn't selected yet, only drag that one
    const isSelected = selectedItems.some(i => {
      return i.id === activeItem.id;
    });
    const itemsToDrag = isSelected ? selectedItems : [activeItem];
    setDraggingItems(itemsToDrag);
  }, [selectedItems]);
  const {
    mutateAsync: addPeopleToList,
    error
  } = client.lists.addPeopleToList.useMutation();
  return <DndContextState.Provider value={{
    selectedItems,
    toggleSelection,
    isItemSelected,
    registerItem,
    unregisterItem,
    draggingItems
  }} data-sentry-element="unknown" data-sentry-component="DndContextProvider" data-sentry-source-file="draggable-context.tsx">
			{/* The DndContext from Dnd Kit handles the core drag events */}
			<DndContext onDragStart={onDragStart} onDragMove={event => {
      setIsOver(Boolean(event.over));
    }} onDragCancel={() => {
      setIsOver(false);
    }} onDragEnd={async event => {
      if (event.over && Number(event.over.id) === 0) {
        setDialogItems(draggingItems.map(item => {
          return {
            networkId: item.networkId,
            networkType: item.networkType
          };
        }));
      }
      if (event.over && Number(event.over.id) > 0) {
        const listName = String(event.over.data.current?.label);
        const listId = Number(event.over.id);
        const toastResult = toast.promise(addPeopleToList({
          listId,
          people: draggingItems.map(item => {
            return {
              networkId: item.networkId,
              networkType: item.networkType
            };
          })
        }), {
          loading: `Adding ${pluralize('people', draggingItems.length, true)} to ${listName}.`,
          error: error?.message ?? 'An unknown error occurred.',
          success: numAdded => {
            const alreadyExisted = numAdded !== draggingItems.length;
            if (numAdded === 0) {
              if (draggingItems.length === 1) {
                return `${draggingItems[0].label} already exists in ${listName}.`;
              }
              return `All ${draggingItems.length} people already existed in the list ${listName}.`;
            }
            if (draggingItems.length === 1) {
              return `${draggingItems[0].label} has been successfully added to ${listName}`;
            }
            return `${pluralize('people', numAdded, true)} have been successfully added to ${listName}.${alreadyExisted ? ` ${pluralize('people', draggingItems.length - numAdded)} already existed in the list.` : ''}`;
          }
        });
        await toastResult.unwrap();
        refresh();
      }
      setDraggingItems([]);
    }} modifiers={[snapCenterToCursor]} data-sentry-element="DndContext" data-sentry-source-file="draggable-context.tsx">
				{children}
				{/* Optional DragOverlay for custom previews */}
				<DragOverlay dropAnimation={isOver ? null : {
        duration: 250,
        easing: 'ease-in-out'
      }} data-sentry-element="DragOverlay" data-sentry-source-file="draggable-context.tsx">
					{draggingItems.length === 1 ? <div className="max-w-fit cursor-grabbing bg-neutral-200 px-3 py-2 shadow-lg smooth-corners-sm">
							{draggingItems[0].label}
						</div> : draggingItems.length > 1 ? <div className="max-w-fit cursor-grabbing bg-neutral-200 px-3 py-2 shadow-lg smooth-corners-sm">
							{pluralize('person', draggingItems.length, true)}
						</div> : null}
				</DragOverlay>
			</DndContext>
			<NewListWithDropDialog open={dialogItems.length > 0} onOpenChange={useCallback(open => {
      if (!open) {
        refresh();
        setDialogItems([]);
      }
    }, [refresh, setDialogItems])} people={dialogItems} data-sentry-element="NewListWithDropDialog" data-sentry-source-file="draggable-context.tsx" />
		</DndContextState.Provider>;
}

/** Helper hook for consuming the DndContextValue in any component. */
export function useDndContext() {
  const ctx = useContext(DndContextState);
  if (!ctx) {
    throw new Error('useDndContext must be used within a DndContextProvider.');
  }
  return ctx;
}