'use client';

import { useState, useEffect, useRef, type RefObject } from 'react';
import { IconButton, Tooltip, Card, Badge } from '@radix-ui/themes';
import { Bell, X, Checks, Archive } from '@phosphor-icons/react/dist/ssr';
import { useKnockClient, useNotifications, useNotificationStore } from '@knocklabs/react';
import { publicConfig } from '~/config';
import Notification from './notification';
import EmptyFeed from './_modules/empty-feed';
import { useOnClickOutside } from 'usehooks-ts';
const NotificationAlert = () => {
  return <span className="absolute right-1 top-1 flex size-2" data-sentry-component="NotificationAlert" data-sentry-source-file="feed.tsx">
			<span className="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75" />
			<span className="relative inline-flex size-2 rounded-full bg-red-500" />
		</span>;
};
const NotificationsFeed = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref as RefObject<HTMLDivElement>, () => {
    setOpen(false);
  });
  const knockClient = useKnockClient();
  const feedClient = useNotifications(knockClient, publicConfig.knock.defaultChannel, {
    archived: 'exclude'
  });
  const {
    items,
    metadata
  } = useNotificationStore(feedClient);
  useEffect(() => {
    feedClient.fetch().catch(error => {
      console.error('Failed to fetch notifications:', error);
    });
  }, [feedClient]);
  const renderHeader = () => {
    return <div className="flex items-center justify-between border-b p-4" data-sentry-component="renderHeader" data-sentry-source-file="feed.tsx">
				<div className="flex items-center gap-2">
					<span className="font-semibold">
						{'Notifications'}
					</span>
					{metadata.unseen_count > 0 && <Badge>
							{`${metadata.unseen_count} new`}
						</Badge>}
				</div>
				<IconButton onClick={() => {
        setOpen(false);
      }} size="1" variant="ghost" color="gray" data-sentry-element="IconButton" data-sentry-source-file="feed.tsx">
					<X size={24} data-sentry-element="X" data-sentry-source-file="feed.tsx" />
				</IconButton>
			</div>;
  };
  const renderFooter = () => {
    return items.length > 0 ? <div className="flex items-center justify-between p" data-sentry-component="renderFooter" data-sentry-source-file="feed.tsx">
				<button className="flex cursor-pointer items-center gap-1 text-xs hover:opacity-75" onClick={() => {
        return void feedClient.markAllAsArchived();
      }}>
					<Archive size={20} data-sentry-element="Archive" data-sentry-source-file="feed.tsx" />
					{'Archive all'}
				</button>
				<button className="flex items-center gap-1 text-xs hover:opacity-75" onClick={() => {
        return void feedClient.markAllAsSeen();
      }}>
					<Checks size={20} data-sentry-element="Checks" data-sentry-source-file="feed.tsx" />
					{'Mark all as read'}
				</button>
			</div> : null;
  };
  const renderNotifications = () => {
    return <div className="max-h-[400px] overflow-y-auto" data-sentry-component="renderNotifications" data-sentry-source-file="feed.tsx">
				{items.length === 0 ? <EmptyFeed /> : items.map(item => {
        return <Notification key={item.id} item={item} />;
      })}
			</div>;
  };
  return <div className="relative" data-sentry-component="NotificationsFeed" data-sentry-source-file="feed.tsx">
			<Tooltip content="Notifications" data-sentry-element="Tooltip" data-sentry-source-file="feed.tsx">
				<IconButton onClick={() => {
        setOpen(open => {
          return !open;
        });
      }} size="2" className="relative rounded-full" variant="ghost" color="gray" data-sentry-element="IconButton" data-sentry-source-file="feed.tsx">
					<Bell size={24} data-sentry-element="Bell" data-sentry-source-file="feed.tsx" />
					{metadata.unseen_count > 0 && <NotificationAlert />}
				</IconButton>
			</Tooltip>
			{open ? <Card variant="classic" className="absolute left-0 top-10 z-50 w-[400px] origin-top-right p-0 shadow" ref={ref}>
					<div>
						{renderHeader()}
						{renderNotifications()}
						{renderFooter()}
					</div>
				</Card> : null}
		</div>;
};
export default NotificationsFeed;