'use client';

import { useUser } from '@clerk/nextjs';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
const PostHogIdentify = ({
  distinctId
}: {
  distinctId: string;
}) => {
  const posthog = usePostHog();
  const {
    user
  } = useUser();

  // Track User
  useEffect(() => {
    if (user) {
      posthog.identify(distinctId, {
        name: user.fullName,
        email: user.primaryEmailAddress?.emailAddress
      });
    }
  }, [distinctId, posthog, user]);
  return null;
};
export default PostHogIdentify;