'use client';

import { useState } from 'react';
import { Button, Dialog, Flex, Text, TextField, Spinner, RadioGroup } from '@radix-ui/themes';
import Link from 'next/link';
import { parseWithZod } from '@conform-to/zod';
import { useForm, useInputControl, getFormProps, getInputProps } from '@conform-to/react';
import { z } from 'zod';
import { client } from '~/trpc/client';
import { publicConfig } from '~/config';
import { Plus } from '@phosphor-icons/react/dist/ssr';
import { usePostHog } from 'posthog-js/react';
const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  relationship: z.enum(['colleague', 'investor', 'advisor', 'customer', 'friend', 'family'], {
    errorMap: () => {
      return {
        message: 'Please select a relationship'
      };
    }
  })
});
const getInviteText = (name: string, code: string) => {
  return `Hey ${name}! I'm using Dunbar to maintain meaningful relationships in my inner circle.
Would love to have you join me here: ${publicConfig.app.host}/share/invite/${code}`;
};
const InviteSuperConnectorButton = () => {
  const [step, setStep] = useState<'form' | 'generate_link'>('form');
  const [generatingLink, setGeneratingLink] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');
  const [copied, setCopied] = useState(false);
  const {
    mutateAsync: generateLink
  } = client.superConnectors.generateLink.useMutation();
  const [form, fields] = useForm({
    onValidate({
      formData
    }) {
      return parseWithZod(formData, {
        schema
      });
    },
    shouldValidate: 'onSubmit',
    shouldRevalidate: 'onSubmit',
    onSubmit: async (event, {
      formData
    }) => {
      event.preventDefault();
      const submission = parseWithZod(formData, {
        schema
      });
      if (submission.status !== 'success') {
        return;
      }
      setStep('generate_link');
      setGeneratingLink(true);
      const {
        code
      } = await generateLink(submission.value);
      setGeneratedLink(code);
      setGeneratingLink(false);
    }
  });
  const relationshipControl = useInputControl(fields.relationship);
  const handleCopyLink = async () => {
    const text = getInviteText(fields.firstName.value ?? '', generatedLink);
    await navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const {
    key: firstNameKey,
    ...firstNameProps
  } = getInputProps(fields.firstName, {
    type: 'text'
  });
  const {
    key: lastNameKey,
    ...lastNameProps
  } = getInputProps(fields.lastName, {
    type: 'text'
  });
  const posthog = usePostHog();
  return <Dialog.Root data-sentry-element="unknown" data-sentry-component="InviteSuperConnectorButton" data-sentry-source-file="invite-super-connector-button.tsx">
			<Dialog.Trigger onClick={() => {
      posthog.capture('super_connectors:invite_button_click');
    }} data-sentry-element="unknown" data-sentry-source-file="invite-super-connector-button.tsx">
				<button className="flex w-full items-center gap-2 px py-sm font-medium hover:bg-neutral-200">
					<Plus size={24} data-sentry-element="Plus" data-sentry-source-file="invite-super-connector-button.tsx" />
					<span>
						{'Add Super Connectors'}
					</span>
				</button>
			</Dialog.Trigger>
			<Dialog.Content maxWidth="450px" data-sentry-element="unknown" data-sentry-source-file="invite-super-connector-button.tsx">
				<Dialog.Title data-sentry-element="unknown" data-sentry-source-file="invite-super-connector-button.tsx">
					{'Invite a super connector'}
				</Dialog.Title>
				<Dialog.Description size="2" mb="4" data-sentry-element="unknown" data-sentry-source-file="invite-super-connector-button.tsx">
					{`Super connectors are your most trusted allies who are invested in your success, and vice versa. 
					 Invite and connect with them on Dunbar to intentionally share and expand your network. For casual sharing of Dunbar, consider using a `}
					<Link href="/gift-links" className="text-violet-600 hover:underline" data-sentry-element="Link" data-sentry-source-file="invite-super-connector-button.tsx">
						{'gift link'}
					</Link>
					{' instead!'}
				</Dialog.Description>
				<form {...getFormProps(form)} noValidate>
					{step === 'form' ? <div className="grid grid-cols-12 gap-sm">
							<div className="col-span-6">
								<label>
									<Text as="div" size="2" mb="1" weight="bold">
										{'First name'}
									</Text>
									<TextField.Root key={firstNameKey} {...firstNameProps} />
								</label>
							</div>
							<div className="col-span-6">
								<label>
									<Text as="div" size="2" mb="1" weight="bold">
										{'Last name'}
									</Text>
									<TextField.Root key={lastNameKey} {...lastNameProps} />
								</label>
							</div>
							<div className="col-span-12">
								<label>
									<Text as="div" size="2" mb="1" weight="bold">
										{'Relationship'}
									</Text>
									<RadioGroup.Root onValueChange={value => {
                relationshipControl.change(value);
              }} name={fields.relationship.name}>
										<RadioGroup.Item value="colleague">
											{'Colleague'}
										</RadioGroup.Item>
										<RadioGroup.Item value="investor">
											{'Investor'}
										</RadioGroup.Item>
										<RadioGroup.Item value="advisor">
											{'Advisor'}
										</RadioGroup.Item>
										<RadioGroup.Item value="customer">
											{'Customer'}
										</RadioGroup.Item>
										<RadioGroup.Item value="friend">
											{'Friend'}
										</RadioGroup.Item>
										<RadioGroup.Item value="family">
											{'Family'}
										</RadioGroup.Item>
									</RadioGroup.Root>
								</label>
							</div>
						</div> : <div className="flex h-[120px] flex-col items-center justify-center gap-sm rounded bg-neutral-100 px-sm">
							{generatingLink ? <>
									<Spinner />
									<Text size="2">
										{'Generating invitation link'}
									</Text>
								</> : <div className="w-full text-sm">
									{getInviteText(fields.firstName.value ?? '', generatedLink)}
								</div>}
						</div>}
					<Flex gap="3" mt="4" justify="end" data-sentry-element="Flex" data-sentry-source-file="invite-super-connector-button.tsx">
						{step === 'form' ? <>
								<Dialog.Close>
									<Button variant="soft" color="gray">
										{'Cancel'}
									</Button>
								</Dialog.Close>
								<Button variant="classic" type="submit" disabled={Boolean(form.errors?.length) || !fields.firstName.value || !fields.lastName.value || !fields.relationship.value}>
									{'Continue'}
								</Button>
							</> : <Button variant="classic" onClick={handleCopyLink} disabled={generatingLink || !generatedLink}>
								{copied ? 'Copied!' : 'Copy link'}
							</Button>}
					</Flex>
				</form>
			</Dialog.Content>
		</Dialog.Root>;
};
export default InviteSuperConnectorButton;