'use client';

import { useState } from 'react';
import { CopySimple, Check } from '@phosphor-icons/react/dist/ssr';
import { Button } from '@radix-ui/themes';
import { publicConfig } from '~/config';
const CopyCodeButton = ({
  code
}: Readonly<{
  code: string;
}>) => {
  const [copied, setCopied] = useState(false);
  const copyCode = async () => {
    const url = `Here’s a link to Dunbar, the app I was telling you about! You'll get 1 month free of the pro plan using my code:\n\n${publicConfig.app.host}/share/gift/${code}`;
    await navigator.clipboard.writeText(url);
    setCopied(true);
  };
  return <Button size="2" variant="classic" onClick={copyCode} className="w-[100px]" data-sentry-element="Button" data-sentry-component="CopyCodeButton" data-sentry-source-file="copy-code-button.tsx">
			{copied ? <Check /> : <CopySimple />}
			{copied ? 'Copied!' : 'Copy'}
		</Button>;
};
export default CopyCodeButton;