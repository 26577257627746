'use client';

import type { ReactNode } from 'react';
import AblyChannelProvider from '~/app/(app)/_providers/ably-channel-provider';
import { toast } from 'sonner';
import useAblyChannel from '~/hooks/use-ably-channel';
const AblyRootChannelProvider = ({
  children
}: Readonly<{
  children: ReactNode;
}>) => {
  return <AblyChannelProvider channelName="notifications" data-sentry-element="AblyChannelProvider" data-sentry-component="AblyRootChannelProvider" data-sentry-source-file="ably-root-channel-provider.tsx">
			<AblyListener data-sentry-element="AblyListener" data-sentry-source-file="ably-root-channel-provider.tsx" />
			{children}
		</AblyChannelProvider>;
};
const AblyListener = () => {
  useNotificationsMessage();
  return null;
};
const useNotificationsMessage = () => {
  return useAblyChannel<{
    message: string;
    description?: string;
  }>('notifications', 'message', message => {
    if (message.data) {
      toast.message(message.data.message);
    }
  });
};
export default AblyRootChannelProvider;