'use client';

import { KnockProvider } from '@knocklabs/react';
import { publicConfig } from '~/config';
import type { ReactNode } from 'react';
const KnockNotificationsProvider = ({
  children,
  customerId
}: Readonly<{
  children: ReactNode;
  customerId?: number | null;
}>) => {
  if (customerId === undefined || customerId === null) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
				{children}
			</>
    );
  }
  return <KnockProvider apiKey={publicConfig.knock.publicKey} userId={String(customerId)} data-sentry-element="KnockProvider" data-sentry-component="KnockNotificationsProvider" data-sentry-source-file="knock-provider.tsx">
			{children}
		</KnockProvider>;
};
export default KnockNotificationsProvider;