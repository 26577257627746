import { Button } from '@radix-ui/themes';
import Link from 'next/link';
const CSVImportCompleteNotification = ({
  listId
}: Readonly<{
  listId: number;
}>) => {
  return <div className="flex items-center gap-2" data-sentry-component="CSVImportCompleteNotification" data-sentry-source-file="csv-import-complete.tsx">
			<Link href={`/lists/${listId}`} data-sentry-element="Link" data-sentry-source-file="csv-import-complete.tsx">
				<Button variant="classic" data-sentry-element="Button" data-sentry-source-file="csv-import-complete.tsx">
					{'View list'}
				</Button>
			</Link>
		</div>;
};
export default CSVImportCompleteNotification;