'use client';

import { Plus, ListPlus, FileCsv } from '@phosphor-icons/react/dist/ssr';
import { DropdownMenu, Button } from '@radix-ui/themes';
import NewListDialog from '~/app/(app)/_modules/sidebar/new-list-dialog';
import { useState } from 'react';
import Link from 'next/link';
const AddListButton = () => {
  const [openNewListDialog, setNewListDialogOpen] = useState(false);
  return <>
			<DropdownMenu.Root data-sentry-element="unknown" data-sentry-source-file="add-list-button.tsx">
				<DropdownMenu.Trigger data-sentry-element="unknown" data-sentry-source-file="add-list-button.tsx">
					<Button variant="soft" aria-label="Add List options" data-sentry-element="Button" data-sentry-source-file="add-list-button.tsx">
						{'Add'}
						<Plus size={16} weight="bold" className="transition-all" data-sentry-element="Plus" data-sentry-source-file="add-list-button.tsx" />
					</Button>
				</DropdownMenu.Trigger>
				<DropdownMenu.Content data-sentry-element="unknown" data-sentry-source-file="add-list-button.tsx">
					<DropdownMenu.Item className="flex items-center gap-sm" onSelect={() => {
          setNewListDialogOpen(true);
        }} data-sentry-element="unknown" data-sentry-source-file="add-list-button.tsx">
						<ListPlus size={16} weight="bold" data-sentry-element="ListPlus" data-sentry-source-file="add-list-button.tsx" />
						<div>
							{'New List'}
						</div>
					</DropdownMenu.Item>
					<DropdownMenu.Item data-sentry-element="unknown" data-sentry-source-file="add-list-button.tsx">
						<Link href="/integrations" className="flex items-center gap-sm" data-sentry-element="Link" data-sentry-source-file="add-list-button.tsx">
							<FileCsv size={16} weight="bold" data-sentry-element="FileCsv" data-sentry-source-file="add-list-button.tsx" />
							<div>
								{'CSV Upload'}
							</div>
						</Link>

					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Root>
			<NewListDialog open={openNewListDialog} onOpenChange={setNewListDialogOpen} data-sentry-element="NewListDialog" data-sentry-source-file="add-list-button.tsx" />
		</>;
};
export default AddListButton;