import { useDroppable } from '@dnd-kit/core';
import type { ReactElement } from 'react';
export const DroppableArea = ({
  id,
  label,
  children
}: Readonly<{
  id: number;
  label: string;
  children: (params: {
    ref: (element: HTMLElement | null) => void;
    isOver: boolean;
  }) => ReactElement;
}>) => {
  const {
    isOver,
    setNodeRef
  } = useDroppable({
    id,
    data: {
      label
    }
  });
  return children({
    ref: setNodeRef,
    isOver
  });
};