'use client';

import { useSync } from '~/app/(app)/_providers/sync-provider';
import useLoopState from '~/hooks/use-loop-state';
import { LinkedinLogo, ArrowsClockwise } from '@phosphor-icons/react/dist/ssr';
const SyncStatus = () => {
  const {
    linkedIn
  } = useSync();
  const state = useLoopState(['.', '..', '...'], 500);
  if (linkedIn.syncing) {
    const stageMapping = {
      preparing: 'Preparing',
      ingestion: 'Syncing',
      enrichment: 'Enriching',
      summarization: 'Summarizing',
      vectorization: 'Indexing',
      profiling: 'Updating',
      measuring_connection_strength: 'Strengthening',
      finalizing: 'Finalizing'
    } satisfies Record<typeof linkedIn.stage, string>;
    return <div className="w-full p">
				<div className="flex w-full items-center justify-between rounded bg-brands-linkedin-blue px py-sm text-sm text-white">
					<div className="flex animate-pulse items-center gap-sm">
						<ArrowsClockwise className="animate-spin" size={20} />
						<div className="flex items-center">
							<div className="truncate">
								{stageMapping[linkedIn.stage]}
							</div>
							<div>
								{state}
							</div>
						</div>
					</div>
					<LinkedinLogo className="animate-pulse" size={20} />
				</div>
			</div>;
  }
};
export default SyncStatus;