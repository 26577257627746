'use client';

import { Dialog, Button, Text, TextField } from '@radix-ui/themes';
import { useForm, getFormProps, getInputProps } from '@conform-to/react';
import { parseWithZod, getZodConstraint } from '@conform-to/zod';
import { createNewListSchema } from '~/app/(app)/_modules/sidebar/schemas';
import pluralize from 'pluralize';
import { client } from '~/trpc/client';
import { toast } from 'sonner';
const NewListWithDropDialog = ({
  open,
  onOpenChange,
  people
}: Readonly<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  people: {
    networkId: string;
    networkType: 'linkedin' | 'csv' | 'x';
  }[];
}>) => {
  const {
    mutateAsync: createListWithPeople,
    error,
    isPending
  } = client.lists.createListWithPeople.useMutation({
    onSuccess: () => {
      onOpenChange(false);
    }
  });
  const [form, fields] = useForm({
    constraint: getZodConstraint(createNewListSchema),
    onValidate: ({
      formData
    }) => {
      return parseWithZod(formData, {
        schema: createNewListSchema
      });
    },
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onSubmit',
    onSubmit: (event, {
      formData
    }) => {
      event.preventDefault();
      const name = formData.get('name') as string;
      toast.promise(createListWithPeople({
        listName: name,
        people
      }), {
        error: error?.message ?? 'An unknown error occurred.',
        success: numAdded => {
          const alreadyExisted = numAdded !== people.length;
          return `${pluralize('people', numAdded, true)} have been successfully added to ${name}.${alreadyExisted ? ` ${pluralize('people', people.length - numAdded)} already existed in the list.` : ''}`;
        }
      });
    }
  });
  const {
    key: nameKey,
    ...nameProps
  } = getInputProps(fields.name, {
    type: 'text'
  });
  return <Dialog.Root open={open} onOpenChange={onOpenChange} data-sentry-element="unknown" data-sentry-component="NewListWithDropDialog" data-sentry-source-file="new-list-with-drop-dialog.tsx">
			<Dialog.Content asChild={true} data-sentry-element="unknown" data-sentry-source-file="new-list-with-drop-dialog.tsx">
				<form {...getFormProps(form)} className="flex max-w-md flex-col gap">
					<div>
						<Dialog.Title data-sentry-element="unknown" data-sentry-source-file="new-list-with-drop-dialog.tsx">
							{`Add ${pluralize('person', people.length, true)} to a new list`}
						</Dialog.Title>
						<Dialog.Description data-sentry-element="unknown" data-sentry-source-file="new-list-with-drop-dialog.tsx">
							{'Create a new list to curate your network.'}
						</Dialog.Description>
					</div>
					<div className="flex flex-col gap">
						<label>
							<Text as="div" size="2" mb="1" weight="bold" data-sentry-element="Text" data-sentry-source-file="new-list-with-drop-dialog.tsx">
								{'Name'}
							</Text>
							<TextField.Root key={nameKey} {...nameProps} size="3" placeholder="eg. Pre-seed Investors" autoComplete="off" data-1p-ignore data-sentry-element="unknown" data-sentry-source-file="new-list-with-drop-dialog.tsx" />
							{fields.name.errors ? <Text as="div" size="1" className="mt-sm" color="crimson">
									{fields.name.errors.map(error => {
                return error;
              })}
								</Text> : null}
						</label>
					</div>
					<div className="mt flex justify-end gap">
						<Dialog.Close data-sentry-element="unknown" data-sentry-source-file="new-list-with-drop-dialog.tsx">
							<Button variant="soft" color="gray" type="button" data-sentry-element="Button" data-sentry-source-file="new-list-with-drop-dialog.tsx">
								{'Cancel'}
							</Button>
						</Dialog.Close>
						<Button loading={isPending} type="submit" disabled={!form.valid} data-sentry-element="Button" data-sentry-source-file="new-list-with-drop-dialog.tsx">
							{'Create'}
						</Button>
					</div>
				</form>
			</Dialog.Content>
		</Dialog.Root>;
};
export default NewListWithDropDialog;