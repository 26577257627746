import { Avatar } from '@radix-ui/themes';
import { User } from '@phosphor-icons/react/dist/ssr';
const SuperConnectorRequestNotification = ({
  connectorName,
  connectorAvatar
}: Readonly<{
  connectorName: string;
  connectorAvatar: string;
}>) => {
  return <div data-sentry-component="SuperConnectorRequestNotification" data-sentry-source-file="super-connector-request.tsx">
			<div className="flex items-center gap-2">
				<Avatar radius="full" src={connectorAvatar} fallback={<User size={24} />} size="3" data-sentry-element="Avatar" data-sentry-source-file="super-connector-request.tsx" />
				<span>
					{`${connectorName} has accepted your super connector request.`}
				</span>
			</div>
		</div>;
};
export default SuperConnectorRequestNotification;