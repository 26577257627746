import type { Recipient } from '@knocklabs/client';
import Image from 'next/image';
import { Text } from '@radix-ui/themes';
import Markdown from 'markdown-to-jsx';
import type { ReactNode } from 'react';
import { formatDistance } from 'date-fns';
const getActorName = (actor: Recipient): string => {
  return 'name' in actor ? actor.name ?? '' : '';
};
const getActorAvatar = (actor: Recipient): string => {
  return 'avatar' in actor ? actor.avatar ?? '' : '';
};
const Root = ({
  children,
  isRead
}: Readonly<{
  children: ReactNode;
  isRead: boolean;
}>) => {
  return <div className={`border-b p-4 ${isRead ? 'bg-white' : 'bg-neutral-100'}`} data-sentry-component="Root" data-sentry-source-file="notification-item.tsx">
			{children}
		</div>;
};
const Header = ({
  actor,
  timeStamp
}: Readonly<{
  actor: Recipient | null;
  timeStamp: string;
}>) => {
  return <div className="flex items-center justify-between" data-sentry-component="Header" data-sentry-source-file="notification-item.tsx">
			<div className="flex items-center gap-2">
				{actor ? <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-violet-100">
						<Image src={getActorAvatar(actor)} className="size-full rounded-full object-cover" alt="Dunbar Logo" width={20} height={20} />
					</div> : <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-violet-100">
						<Image src="/logos/dunbar-icon.svg" alt="Dunbar Logo" width={20} height={20} className="rounded-full object-cover" />
					</div>}
				<div className="flex flex-col">
					<Text weight="medium" data-sentry-element="Text" data-sentry-source-file="notification-item.tsx">
						{actor ? getActorName(actor) : 'Dunbar'}
					</Text>
				</div>
			</div>
			<Text size="1" color="gray" data-sentry-element="Text" data-sentry-source-file="notification-item.tsx">
				{formatDistance(new Date(timeStamp), new Date(), {
        addSuffix: true
      })}
			</Text>
		</div>;
};
const Content = ({
  children,
  message
}: Readonly<{
  children: ReactNode;
  message: string;
}>) => {
  return <div className="mt-4 w-full" data-sentry-component="Content" data-sentry-source-file="notification-item.tsx">
			<Markdown className="text-sm" data-sentry-element="Markdown" data-sentry-source-file="notification-item.tsx">
				{message}
			</Markdown>
			{children}
		</div>;
};
export const NotificationItem = {
  Root,
  Header,
  Content
};