'use client';

import { DroppableArea } from '~/modules/draggable-people/droppable-area';
import { Folder, FolderOpen } from '@phosphor-icons/react';
import { useParams } from 'next/navigation';
import { LinkedinLogo, FileCsv } from '@phosphor-icons/react/dist/ssr';
import ListTemplate from '~/app/(app)/_modules/sidebar/list-template';
import numeral from 'numeral';
const List = ({
  id,
  name,
  type,
  totalPeople
}: Readonly<{
  id: number;
  name: string;
  type: 'linkedin' | 'csv' | 'custom';
  totalPeople: number;
}>) => {
  const params = useParams();
  const disableDropping = type === 'linkedin' || type === 'csv';
  const isCurrentList = Number(params.listId) === id;
  if (disableDropping) {
    const formattedNumber = numeral(totalPeople).format('0,0');
    return <ListTemplate isActiveList={isCurrentList} href={`/lists/${id}`} badge={formattedNumber} name={name} icon={type === 'linkedin' ? <LinkedinLogo size={26} weight="thin" /> : <FileCsv size={26} weight="thin" />} />;
  }
  return <DroppableArea id={id} label={name} data-sentry-element="DroppableArea" data-sentry-component="List" data-sentry-source-file="list.tsx">
			{({
      ref,
      isOver
    }) => {
      return <ListTemplate isActiveList={isCurrentList} name={name} href={`/lists/${id}`} ref={ref} className={isOver ? 'bg-dunbar-purple/10' : undefined} badge={totalPeople.toLocaleString('en-US')} icon={isOver ? <FolderOpen weight="thin" size={26} /> : <Folder weight="thin" size={26} />} />;
    }}
		</DroppableArea>;
};
export default List;