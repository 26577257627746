'use client';

import dynamic from 'next/dynamic';
const UserButtonLink = dynamic(async () => {
  return import('./user-button-link');
}, {
  ssr: false
});
const AuthClient = () => {
  return <UserButtonLink data-sentry-element="UserButtonLink" data-sentry-component="AuthClient" data-sentry-source-file="auth-client.tsx" />;
};
export default AuthClient;