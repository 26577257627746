import { Confetti } from '@phosphor-icons/react/dist/ssr';
const EmptyFeed = () => {
  return <div className="flex h-[400px] flex-col items-center justify-center bg-neutral-100 p-4" data-sentry-component="EmptyFeed" data-sentry-source-file="empty-feed.tsx">
			<div className="flex flex-col items-center justify-center gap-2">
				<div className="flex size-8 items-center justify-center rounded-full bg-violet-100">
					<Confetti size={20} data-sentry-element="Confetti" data-sentry-source-file="empty-feed.tsx" />
				</div>
				<div>
					<span className="font-semibold">
						{'You\'re all caught up!'}
					</span>
				</div>
			</div>
		</div>;
};
export default EmptyFeed;