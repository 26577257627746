'use client';

import { useCustomer } from '~/app/(app)/_providers/customer-provider';
import type { ReactNode } from 'react';
import { ChannelProvider } from 'ably/react';
const AblyChannelProvider = ({
  channelName,
  children
}: Readonly<{
  channelName?: string;
  children: ReactNode;
}>) => {
  const customer = useCustomer();
  return <ChannelProvider channelName={channelName ? `customer:${customer.customerId}:${channelName}` : `customer:${customer.customerId}`} data-sentry-element="ChannelProvider" data-sentry-component="AblyChannelProvider" data-sentry-source-file="ably-channel-provider.tsx">
			{children}
		</ChannelProvider>;
};
export default AblyChannelProvider;