'use client';

import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import { type ReactNode, useState, useEffect } from 'react';
import { useAuth } from '@clerk/nextjs';
export function AblyMessagesProvider({
  children
}: Readonly<{
  children: ReactNode;
}>) {
  const {
    userId
  } = useAuth();
  const [ablyClient, setAblyClient] = useState<Ably.Realtime | null>(null);
  useEffect(() => {
    if (userId) {
      const ably = new Ably.Realtime({
        authUrl: '/api/ably',
        authMethod: 'GET'
      });
      setAblyClient(ably);
      return () => {
        ably.close();
      };
    }
  }, [userId]);
  if (!ablyClient) {
    return null;
  }
  return <AblyProvider client={ablyClient} data-sentry-element="AblyProvider" data-sentry-component="AblyMessagesProvider" data-sentry-source-file="ably-provider.tsx">
			{children}
		</AblyProvider>;
}
export default AblyMessagesProvider;