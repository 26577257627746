import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

const useLoopState = <T>(steps: T[], ms: number) => {
	if (steps.length === 0) {
		throw new Error('steps must have more than 1')
	}

	const [
		step,
		setStep,
	] = useState(0)

	useInterval(() => {
		setStep((step) => {
			const nextStep = step + 1

			if (nextStep > steps.length - 1) {
				return 0
			}

			return nextStep
		})
	}, ms)

	return steps[step]
}

export default useLoopState
