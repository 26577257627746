import { useCustomer } from '~/app/(app)/_providers/customer-provider'
import { useChannel } from 'ably/react'
import type { Message } from 'ably'

type ChannelMessage<T> = Omit<Message, 'data'> & {
	data?: T
}

const useAblyChannel = <T = unknown>(channelName: string, messageName: string, onMessage: (message: ChannelMessage<T>) => Promise<void> | void) => {
	const customer = useCustomer()

	return useChannel(`customer:${customer.customerId}:${channelName}`, messageName, async (message) => {
		await onMessage(message)
	})
}

export default useAblyChannel
